import React from "react";

const ProfileImage = () => {

    return (
        <picture className="profile-picture">
            <source
                media="(max-width: 767px)"                
                sizes="(max-width: 767px) 100vw, 767px"
                srcSet="
                images/derya2_responsive/derya2_o7wee2_ar_1_1,c_fill,g_auto__c_scale,w_300.jpg 300w,
                images/derya2_responsive/derya2_o7wee2_ar_1_1,c_fill,g_auto__c_scale,w_603.jpg 603w,
                images/derya2_responsive/derya2_o7wee2_ar_1_1,c_fill,g_auto__c_scale,w_767.jpg 767w"></source>
                <source
                    media="(min-width: 768px) and (max-width: 991px)"
                    sizes="(max-width: 991px) 70vw, 694px"
                    srcSet="
                    images/derya2_responsive/derya2_o7wee2_ar_4_3,c_fill,g_auto__c_scale,w_538.jpg 538w,
                    images/derya2_responsive/derya2_o7wee2_ar_4_3,c_fill,g_auto__c_scale,w_694.jpg 694w"></source>
                    <source
                        media="(min-width: 992px) and (max-width: 1199px)"
                        sizes="(max-width: 1200px) 60vw, 720px"
                        srcSet="
                        images/derya2_responsive/derya2_o7wee2_ar_16_9,c_fill,g_auto__c_scale,w_596.jpg 596w,
                        images/derya2_responsive/derya2_o7wee2_ar_16_9,c_fill,g_auto__c_scale,w_720.jpg 720w" ></source>
                        <img
                            sizes="(max-width: 3000px) 40vw, 1200px"
                            srcSet="
                            images/derya2_responsive/derya2_o7wee2_c_scale,w_480.jpg 480w,
                            images/derya2_responsive/derya2_o7wee2_c_scale,w_918.jpg 918w,
                            images/derya2_responsive/derya2_o7wee2_c_scale,w_1200.jpg 1200w"
                            src="images/derya2_responsive/derya2_o7wee2_c_scale,w_1200.jpg"
                            alt=""></img>
    </picture>
    )
}


export default ProfileImage;