import React from 'react'
import { Card, Button } from "react-bootstrap";
import { navigate } from "gatsby";
import { faBookOpen, faGraduationCap, faFilePdf, faCode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileImage from "./profile-image";

const ProfileCard = () => {
    return (
        <Card className="my-3 p-1 rounded">
            <ProfileImage></ProfileImage>
            <Card.Body>                                
                    <Button variant="light" onClick={e => navigate('/research/')} >
                        <FontAwesomeIcon icon={faBookOpen}></FontAwesomeIcon> Research
                    </Button>
                    <Button variant="light" onClick={e => navigate('/resume/')} >
                        <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon> CV
                    </Button>
                    <Button variant="light" onClick={e => navigate('/teaching/')} >
                        <FontAwesomeIcon icon={faGraduationCap}></FontAwesomeIcon> Teaching
                    </Button>
                    <Button variant='light' onClick={e => navigate('/software')}>
                        <FontAwesomeIcon icon={faCode}></FontAwesomeIcon> Software
                    </Button>                
            </Card.Body>
        </Card>
    )
}

export const ProfileDescription = ({ node }) => {

    return (
        <Card className='my-3 p-3 rounded'>            
            <Card.Text>
                {node.description}
            </Card.Text>
        </Card>
    )
}


export default ProfileCard
