import React from 'react'
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faResearchgate, faGithub, faGoogleScholar, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const SocialIcons = () => {
    return (
      <section>
        <h2 className='paper-title'>Contact</h2>
        <div>
            <Button variant='secondary' href="https://github.com/deryauysal">                
              <FontAwesomeIcon icon={faGithub} size="2x" />                
            </Button>
            <Button variant='secondary' href="https://scholar.google.com/citations?user=l2jgZ9sAAAAJ">                
              <FontAwesomeIcon icon={faGoogleScholar}  size="2x" />                
            </Button>
            <Button variant='secondary' href="https://www.researchgate.net/profile/Derya_Uysal">                
              <FontAwesomeIcon icon={faResearchgate} size="2x" />                
            </Button>
            <Button variant='secondary' href="https://twitter.com/sderyauysal">
              <FontAwesomeIcon icon={faXTwitter} size="2x" />                
            </Button>
            <Button variant='secondary' href="http://www.linkedin.com/in/derya-uysal-econometrics">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />                
            </Button>
            <Button variant='secondary' href="mailto:derya.uysal@econ.lmu.de">                
              <FontAwesomeIcon icon={faEnvelope} size="2x" />                
            </Button>            
        </div>
      </section>

    )
}
export default SocialIcons
