import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo";

import ProfileCard, { ProfileDescription } from "../components/profile-card";
import { Row, Col } from "react-bootstrap";
import SocialIcons from "../components/social-icons";

export default function Home({ data }) {
  return (
    <Layout>
      <SEO />      
      <Row>
        <Col md={6}>
          <ProfileCard></ProfileCard>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col>
              <ProfileDescription node={data.site.siteMetadata} />
            </Col>
          </Row>
          <Row>
            <Col>
              <SocialIcons></SocialIcons>
            </Col>
          </Row>
        </Col>
      </Row>      
    </Layout>
  )
}

export const query = 
  graphql`
    query {
      site {
        siteMetadata {
          title
          summary
          description
          author
        }
      }
    }
  `
